import axios from 'axios'
import { ref } from 'vue'
import { useUserStore } from '../stores/user'
import { useRouter } from 'vue-router'

export function useApi() {
  const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
  })

  const errors = ref({})
  const userStore = useUserStore()
  const router = useRouter()

  api.interceptors.request.use((request) => {
    errors.value = {}

    if (userStore.token) {
      request.headers.Authorization = `Bearer ${userStore.token}`
    }

    if (userStore.tenant) {
      request.headers['tenant-id'] = userStore.tenant
    }

    return request
  })

  api.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
      userStore.logout()
      router.push('/login')
    }

    if (error.response.status === 422) {
      errors.value = error.response.data.errors
      error.failedValidation = true
    }

    throw error
  })

  return {
    api,
    errors,
  }
}
